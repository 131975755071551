<template>
  <div class="main_content program_emp_search align-items-center" style="min-height: 80vh">
    
    <div class="row justify-content-between mb_40">
      <div class="col-12 col-md-12 col-xl-9 col-xxl-9">
        <div class="heading">
          <h2 class="Employee_head heading-dark">Follow-Ups</h2>
          <h3 class="Program_dashboard_head">
            <!-- <router-link
              class="text-darks hov_router"
              :to="{ name: 'client_manager' }"
            >
              PBE Management Dashboard
            </router-link>
            / -->
            <router-link class="text-darks hov_router" :to="{ name: 'client_manager' }">
              Program Management
            </router-link>
            /
            <!-- <span class="breadcrumb-item active"> Follow-Ups</span> -->
            <span class="breadcrumb-item active" style="color: #ff9500 !important;"> {{ clientName }}</span>
            <!-- <router-link
              :is="isDisabled ? 'span' : 'router-link'"
              class="text-darks hov_router active"
              :to="{ name: 'client_manager' }"
            >
              Follow-Ups
            </router-link> -->
          </h3>
        </div>
      </div>
    </div>

    <div class="table_card">
      <div class="row dataTables_wrapper no-footer" id="datatable_wrapper">
        <div class=" col-xl-12 col-lg-12 col-md-12 col-xs-12 text-center text-lg-start ">
          <h4 class="font_bold font_size-20 text-darks">Follow-Up Survey</h4>
        </div>
        <div class=" col-xl-4 col-lg-3 col-md-12 col-xs-12 text-center text-lg-start align-self-center my-2 ">
          <div class=" dataTables_length float_none text-center text-lg-start " id="datatable_length">
            <label>Show
              <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                @change="getFollowupsBysearch">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>500</option>
              </select>
              entries</label>
          </div>
        </div>
        <div class="col-xl-8 col-lg-9 col-md-12 col-xs-12">
          <div class=" d-flex justify-content-lg-end justify-content-center align-items-center flex-wrap mb-4 mb-lg-4 ">
            <div id="datatable_filter" class="dataTables_filter my-2">
              <h1 v-if="reportCheckbox.length" class="updatestatus mb-0 text-center me-0 me-lg-3">
                Update Status
              </h1>
              <label v-else class="mb-0"><input type="search" class="" placeholder="Search" aria-controls="datatable"
                  v-model="listConfig.search_string" v-on:keyup.enter="getFollowupsBysearch" />
                <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getFollowupsBysearch"
                  class="search_icon slty_up">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#7d8d9e" class="bi bi-search"
                    viewBox="0 0 16 16">
                    <path
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </a>
                <a href="javascript:void(0)" v-if="listConfig.search_string"
                  @click=" listConfig.search_string = null; getFollowupsBysearch();" class="crossicon x_icon">
                  X
                </a>
              </label>
            </div>
            <div class="visited_pages status_box fwl_sts marg_left my-2">
              <div class="heading">
                <div class=" region_select status_box new_status status_select form-group status_drop mb-0 ">
                  <v-select class="vs-drop-cust" @input="updateBulkStatus($event)"
                    :reduce="(update_status_list) => update_status_list.id" :options="update_status_list" label="name"
                    placeholder="All Status" name="Status" v-model="listConfig.search_status" v-validate="'required'"
                    v-if="reportCheckbox.length" />
                  <v-select class="vs-drop-cust" @input="updateBulkStatus($event)"
                    :reduce="(status_list) => status_list.id" :options="status_list" label="name" placeholder="All Status"
                    name="Status" v-model="listConfig.search_status" v-validate="'required'" v-else />
                  <small class="error text-danger" v-show="errors.has('addUserForm.Status')">
                    {{ errors.first("addUserForm.Status") }}
                  </small>
                </div>
              </div>
            </div>
            <div v-if="reportCheckbox.length && oldSelectedCheckbox != 'Hidden'"
              class="region_select status_box marg_left mb-1">
              <a href="javascript:" class="comman_icons" @click="updateBulkStatus('Hidden')">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16.975" viewBox="0 0 17 16.975">
                  <path id="Path_107" data-name="Path 107"
                    d="M11.431,15.437a7.874,7.874,0,0,1-8.974-5.364A7.836,7.836,0,0,1,3.685,7.693m4.6.714a2.358,2.358,0,0,1,3.335,3.335M8.289,8.406l3.334,3.334M8.291,8.407,5.705,5.821m5.92,5.92,2.586,2.586M2.884,3,5.705,5.821m0,0a7.862,7.862,0,0,1,11.752,4.252,7.879,7.879,0,0,1-3.247,4.253m0,0,2.821,2.821"
                    transform="translate(-1.457 -1.586)" fill="none" stroke="#a9a9a9" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                </svg>
                <span class="ml_10"><b>Hide</b></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div id="myTab2Content" class="tab-content">
            <div id="percent" role="tabpanel" aria-labelledby="#percent" class="tab-pane fade active show">
              <div class="all_user">
                <div class="table-responsive table_audit_log report_table col_big">
                  <table class="table table-bordered table dataTable no-footer">
                    <thead>
                      <tr>
                        <th class="sort sml_check" style="min-width: 10px">
                          <div class=" form_check_box d-flex align-items-center mt-1 ">
                            <input type="checkbox" v-model="selectAll" aria-label="..." id="flexCheckDefault"
                              class="form-check-input ms-0" @change="checkSelected($event)" />
                            <label for="flexCheckDefault"></label>
                          </div>
                        </th>
                        <th scope="col" class="sort col_to_follow">
                          <SortIcons :lable="'FOLLOW-UP ON'" :listConfig="listConfig" :sortBy="'created_at'"
                            @sorting="sorting($event)" />
                        </th>
                        <th scope="col" class="sort col_to_name">
                          <SortIcons :lable="'EMP FIRST NAME'" :listConfig="listConfig" :sortBy="'first_name'"
                            @sorting="sorting($event)" />
                        </th>
                        <th scope="col" class="sort col_to_name">
                          <SortIcons :lable="'EMP LAST NAME'" :listConfig="listConfig" :sortBy="'last_name'"
                            @sorting="sorting($event)" />
                        </th>
                        <th scope="col" class="sort col_to_flw_email">
                          <SortIcons :lable="'Email Address'" :listConfig="listConfig" :sortBy="'Email'"
                            @sorting="sorting($event)" />
                        </th>
                        <th scope="col" class="sort dsk_sml_sts">
                          <SortIcons :lable="'Comfort'" :listConfig="listConfig" :sortBy="'reported_improvement'"
                            @sorting="sorting($event)" />
                        </th>
                        <th scope="col" class="sort flw_ups_status move_icon">
                          <SortIcons :lable="'Status'" :listConfig="listConfig" :sortBy="'status'"
                            @sorting="sorting($event)" />
                          <svg style="margin-left: 10px;"
                            v-tooltip="'Pending, Scheduled, Declined, Hidden, Complete, In Review, Client Follow-Up Needed'"
                            xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                            viewBox="0 0 14 18.346">
                            <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                            <path data-name="Path 9427"
                              d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                              transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                          </svg>
                        </th>
                        <th align="center" style="text-align: center" scope="col" class="dsk_sml_sts">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(followup, indx) in followups_data" :key="indx">
                        <td style="min-width: 10px">
                          <div class="form_check_box d-flex align-items-center">
                            <input type="checkbox" v-model="reportCheckbox" aria-label="..."
                              :id="'flexCheckDefault' + indx" class="form-check-input ms-0"
                              @change="checkSelected($event)" :value="followup.id" />
                            <label :for="'flexCheckDefault' + indx" class=""></label>
                          </div>
                        </td>
                        <td>
                          <p>{{ followup.formated_date }}</p>
                        </td>
                        <td>
                          <p>{{ followup.first_name != null ? followup.first_name : followup.user_name }}</p>
                        </td>
                        <td>
                          <p>{{ followup.last_name }}</p>
                        </td>
                        <td>
                          <p class="email_tag">
                            <a href="mailto:">{{ followup.user_email }}</a>
                          </p>
                        </td>
                        <td>
                          <p>
                            {{ followup.reported_improvement ?? 'NA' }}
                          </p>
                        </td>
                        <td>
                          <p>
                            {{ followup.status }}
                          </p>
                        </td>
                        <td align="center">
                          <div class="edit_btn">
                            <p>
                              <a href="#" @click="completeStatus(followup.id)" aria-current="page" title="Complete" class="
                                  view-dshboad-btn
                                  router-link-exact-active router-link-active
                                  ms-2
                                " v-if="listConfig.search_status != 'Complete' &&
                                  oldSelectedCheckbox != 'Complete'
                                  ">
                                <img :src="require('@/assets/images/dashboard/complete.svg')
                                  " alt="img" class="view-icon" /> </a><a href="javascript:"
                                @click="showReportDataSet(followup.dataset, followup.first_name + ' ' + followup.last_name)"
                                aria-current="page" title="Data Set" class="
                                  router-link-exact-active router-link-active
                                " data-bs-toggle="modal" data-bs-target="#dataset"><img class="view-icon" :src="require('@/assets/images/dashboard/Database.png')
                                  " alt="img" /></a><a href="javascript:"
                                @click="getSingleFollowupHistory(followup.id)" aria-current="page" data-bs-toggle="modal"
                                data-bs-target="#history" title="History" class="
                                  router-link-exact-active router-link-active
                                  view-dshboad-btn
                                "><img :src="require('@/assets/images/dashboard/timer.png')
                                  " alt="img" class="edit-icon" /></a>
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="data_length == 0">
                        <td colspan="6" class="text-center">
                          No Records found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-wrap mt-4">
            <p class="float-lg-start text-sm-center">{{ positionText }}</p>
            <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length" :perPage="itemsPerPage"
              :currentPage.sync="currentPage" @pagechanged="onPageChange" />
          </div>
        </div>
      </div>
    </div>
    <DatasetModal v-bind:downloadMessage="downloadMessage" v-bind:dashboard="dashboard" v-bind:dataSet="dataSet"
      v-bind:isEmptyDataSet="isEmptyDataSet" ref="datasetModal"></DatasetModal>
    <HistoryModal v-bind:history="followupHistory" v-bind:isShowHistory="showHistory" ref="historyModal"></HistoryModal>
    <!-- <div class="modal box_up fade" id="dataset" tabindex="-1" aria-labelledby="downloadLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header download-btn-right">
            <h5 class="modal-title" id="downloadLabel">
              <h2 class="heading_2 font_size-20">Data Set</h2> 
            </h5>
            <div class="down_cross">
              <a href="javascript:" class="ml_12 xl_download" v-if="this.dataSet" @click="exportDataset()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                <g data-name="Group 12689">
                  <path
                    data-name="Path 114"
                    d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                    transform="translate(-3 -3)"
                    style="
                      fill: none;
                      stroke: #a9a9a9;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-width: 2px;
                    "
                  />
                </g>
              </svg>
              Download
            </a>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetDataSet" ></button>
            </div>
          </div>
          <Spinner ref="Spinner"></Spinner>
          <div class="modal-body details_eval">
            <div class="row justify-content-center">
              <div class="col-xs-12 col-md-12 main-timeline cust_scroll">
                <div class="table table-responsive">
                  <table class="table historyTable" width="100%" align="center">
                    <tr>
                      <th style="width: 10%">ID</th>
                      <th style="width: 25%">QUESTION</th>
                      <th style="width: 65%">ANSWER</th>
                    </tr>
                    <tr v-for="(dataValue, dataKey) in dataSet" :key="dataKey">
                      <td v-html="dataKey"></td>
                      <td v-html="dataValue.question"></td>
                      <td v-html="dataValue.answer ? dataValue.answer : 'Not Answered'"></td>
                    </tr>
                    <tr v-if="!this.dataSet">
                      <td colspan="6" class="text-center">No Records found</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="modal fade box_up" id="history" tabindex="-1" aria-labelledby="downloadLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadLabel">
              <h2 class="heading_2 font_size-20">History</h2>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
          </div>
          <Spinner ref="Spinner"></Spinner>
          <div class="modal-body details_eval">
            <div class="row justify-content-center">
              <div class="col-xs-10 col-md-10 main-timeline cust_scroll">
                <div v-show="showHistory">
                  <div class="timeline" v-if="followupHistory.length">
                    <div
                      :class="
                        indx % 2 == 0
                          ? 'container_timeline left'
                          : 'container_timeline right'
                      "
                      v-for="(history, indx) in followupHistory"
                      :key="indx"
                    >
                      <div class="date date_set">
                        {{ history.history_date }}
                      </div>
                      <div class="content">
                        <h1>{{ history.history_key }}</h1>
                        <h2>{{ history.history_value }}</h2>
                        <h6>By: {{ history.user_name }}</h6>
                      </div>
                    </div>
                  </div>
                  <div v-if="!followupHistory.length" class="row">
                    <div class="col-xs-12 col-md-12">
                      <h2 class="text-center no_data mb-5">
                        There is no history
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import commonFunction from "../../mixin/commonFunction";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import ConfirmDialogue from "@/components/ConfirmDialogue";
// import Spinner from "../../components/Spinner.vue";
import exportFromJSON from "export-from-json";
import DatasetModal from "@/components/DatasetModal";
import HistoryModal from "@/components/HistoryModal";
import commonService from "../../services/commonService";

export default {
  name: "client_follow_ups",
  mixins: [commonFunction],
  components: {
    VPagination,
    SortIcons,
    ConfirmDialogue,
    // Spinner,
    DatasetModal,
    HistoryModal,
  },
  data() {
    return {
      downloadMessage: 'Dataset',
      dashboard: 'Program Management Follow-Ups',
      dataSet: [],
      isEmptyDataSet: true,
      show: false,
      followups_data: [],
      followupHistory: [],
      selected: true,
      isLoader: false,
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        search_status: null,
        client_id: null,
      },
      data_length: 0,
      isDisabled: "",
      addPermission: false,
      hasError: false,
      status_list: commonService.followupsReportStatus(),
      update_status_list: commonService.followupsReportUpdateStatus(),
      // status_list: [
      //   {
      //     id: null,
      //     name: "All Status",
      //   },
      //   {
      //     id: "Pending",
      //     name: "Pending",
      //   },
      //   {
      //     id: "Scheduled",
      //     name: "Scheduled",
      //   },
      //   {
      //     id: "No Reply",
      //     name: "No Reply",
      //   },
      //   {
      //     id: "Declined",
      //     name: "Declined",
      //   },
      //   {
      //     id: "Hidden",
      //     name: "Hidden",
      //   },
      //   {
      //     id: "Complete",
      //     name: "Complete",
      //   },
      // ],
      // update_status_list: [
      //   {
      //     id: "Pending",
      //     name: "Pending",
      //   },
      //   {
      //     id: "Scheduled",
      //     name: "Scheduled",
      //   },
      //   {
      //     id: "No Reply",
      //     name: "No Reply",
      //   },
      //   {
      //     id: "Declined",
      //     name: "Declined",
      //   },
      //   {
      //     id: "Complete",
      //     name: "Complete",
      //   },
      // ],
      clearButton: true,
      isCheckAll: false,
      selectedFollowups: [],
      base_url: null,
      reportCheckbox: [],
      reportCheckboxArr: {
        status: null,
        checkboxes: [],
      },
      oldSelectedCheckbox: null,
      showHistory: false,
      clientName: 'Follow-Ups',
    };
  },
  computed: {
    selectAll: {
      get: function () {
        return this.followups_data
          ? this.reportCheckbox.length == this.followups_data.length
          : false;
      },
      set: function (value) {
        var reportCheckbox = [];
        if (value) {
          this.followups_data.forEach(function (data) {
            reportCheckbox.push(data.id);
          });
        }
        this.reportCheckbox = reportCheckbox;
      },
    },
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getFolloups();
    },
  },
  destroyed() {
    this.$parent.clientManagerDashboard = false;
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.base_url = process.env.VUE_APP_API_URL;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "created_at",
      order: "DESC",
      search_string: null,
      search_status: null,
      client_id: this.client_id,
    };
    this.getFolloups();
    if (this.$route.params.successMsg) {
      this.$toast.success(this.$route.params.successMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
    if (this.$route.params.errorMsg) {
      this.$toast.error(this.$route.params.errorMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
    this.$parent.clientManagerDashboard = true;
  },
  beforeMount() {
    // if (localStorage.getItem("userToken")) {
    //   window.addEventListener("scroll", this.scrollvue);
    //   // Store for check permission
    //   let res = this.$store.dispatch("auth/getAuthUser");
    //   res
    //     .then((value) => {
    //       let allPermission = value.user.roles.all_permission;
    //       if (allPermission.includes("users-edit")) {
    //         this.addPermission = true;
    //       }
    //       if (allPermission.includes("users-delete")) {
    //         this.deletePermission = true;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // } else {
    //   this.tokenExpired();
    // }

    if (this.$route.params.id != undefined) {
      this.client_id = this.$route.params.id;
    }
  },
  methods: {
    /** Export Dataset */
    exportDataset() {
      var filterExport = [];
      var _this = this;
      var data;
      if (_this.dataSet) {
        Object.keys(_this.dataSet).forEach(key => {
          filterExport.push({
            "Id": _this.dataSet[key].id,
            "Question": _this.dataSet[key].question.replace(/<[^>]*>?/gm, ''),
            "Answer": _this.dataSet[key].answer ? _this.dataSet[key].answer : "Not Answered",
          });
        });
        data = filterExport;
      }
      const fileName = "followup-dataset";
      const exportType = exportFromJSON.types.csv;
      if (data) exportFromJSON({ data, fileName, exportType });
    },

    resetDataSet() {
      this.dataSet = [];
    },
    async completeStatus(followupid) {
      const confirmComplete = await this.$refs.confirmDialogue.show({
        title: "Complete Follow-up",
        message: "Are you sure you want to complete followup?",
        okButton: "Complete",
      });

      // On Confirm
      if (confirmComplete) {
        if (localStorage.getItem("userToken")) {
          var _this = this;
          var requestData = {
            followupid: followupid,
            status: "Complete",
          };
          _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          let config = {
            method: "post",
            url:
              process.env.VUE_APP_API_URL +
              "/api/client-followup-complete-status",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequest(requestData) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                _this.$toast.success(data.message, {
                  position: "top-right",
                });
                this.reportCheckbox = [];
                this.getFollowupsBysearch();
              } else {
                _this.$toast.error(data.message, {
                  position: "top-right",
                });
              }
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            })
            .catch(({ response }) => {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              _this.$toast.error(response.data.error, {
                position: "top-right",
                duration: 5000,
              });
              if (response.status == 401) {
                _this.$router.push({ name: "login" });
              }
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    checkSelected(event) {
      if (event.target.checked) {
        this.listConfig.search_status = "";
      } else {
        if (this.reportCheckbox.length == 0) {
          this.listConfig.search_status = this.oldSelectedCheckbox;
        }
      }
    },
    showReportDataSet(dataSet, username) {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = true;
        if (dataSet != null) {
          var i = 1;
          for (const item in dataSet) {
            dataSet[item].id = i++;
            if (dataSet[item].options) {
              dataSet[item].answer = '';
              for (const item1 in dataSet[item].options) {
                if (dataSet[item].options[item1].answer) {
                  if (dataSet[item].answer) {
                    dataSet[item].answer += ', ' + dataSet[item].options[item1].answer;
                  }
                  else {
                    dataSet[item].answer = dataSet[item].options[item1].answer;
                  }

                }
              }
            }
            if (dataSet[item].subquestions) {
              dataSet[item].answer = '';
              for (const item2 in dataSet[item].subquestions) {
                if (dataSet[item].subquestions[item2].answer) {
                  if (dataSet[item].answer) {
                    dataSet[item].answer += ', ' + dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                  }
                  else {
                    dataSet[item].answer = dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                  }
                }
              }
            }
          }
          this.dataSet = dataSet;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
          this.isEmptyDataSet = Object.keys(dataSet).length === 0;
          this.downloadMessage = "Dataset of follow up for " + username;
        } else {
          this.dataSet = [];
          this.isEmptyDataSet = true;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        }
      } else {
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        $('.modal-backdrop').remove()
        $(document.body).removeClass("modal-open");
        this.tokenExpired();
      }
    },
    getSingleFollowupHistory(followupid) {
      if (followupid) {
        this.showHistory = false;
        var requestData = { followupid: followupid };
        if (localStorage.getItem("userToken")) {
          var _this = this;
          _this.$refs.historyModal.$refs.Spinner.modalLoader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/followup-all-history",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequest(requestData) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.data);
                _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
                this.followupHistory = decodedJson.records;
                this.showHistory = true;
              }
            })
            .catch(({ response }) => {
              _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
              console.log(response);
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    async updateBulkStatus($event) {
      if (this.reportCheckbox.length && $event != null) {
        const confirmUpdate = await this.$refs.confirmDialogue.show({
          title: "Update Status",
          message: "Are you sure you want to update status?",
          okButton: "Update",
        });

        if (confirmUpdate) {
          this.reportCheckboxArr.status = $event;
          this.reportCheckboxArr.checkboxes = this.reportCheckbox;
          if (localStorage.getItem("userToken")) {
            var _this = this;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            let config = {
              method: "post",
              url:
                process.env.VUE_APP_API_URL +
                "/api/client-followup-status-bulk-edit",
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "userToken"
                )}`,
              },
              data: { request_data: this.encodeAPIRequest(this.reportCheckboxArr) },
            };
            _this
              .axios(config)
              .then(({ data }) => {
                if (data.success) {
                  _this.$toast.success(data.message, {
                    position: "top-right",
                  });
                  this.listConfig.search_status = null;
                  this.reportCheckbox = [];
                  this.getFollowupsBysearch();
                  this.selectAll = false;
                } else {
                  _this.$toast.error(data.message, {
                    position: "top-right",
                  });
                }
                _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              })
              .catch(({ response }) => {
                console.log(response);
              });
          } else {
            this.tokenExpired();
          }
        }
      } else {
        this.reportCheckbox.length = 0;
        this.getFollowupsBysearch();
      }
    },
    userAllcheck: function () {
      if (this.isCheckAll == false) {
        const selected = this.followups_data.map((u) => u.id);
        this.selectedFollowups = selected;
      } else {
        this.selectedFollowups = [];
      }
    },
    resetAll() {
      this.getFolloups();
    },
    onPageChange(page) {
      console.log("page", page);
      this.currentPage = page;
    },
    sorting($event) {
      console.log($event);
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getFolloups();
    },
    getFollowupsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getFolloups();
    },
    getFolloups() {
      var _this = this;
      _this.oldSelectedCheckbox = this.listConfig.search_status;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/client-followup-pagination",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequestWithImages(_this.listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedData = _this.decodeAPIResponse(data.data);
          _this.clientName = decodedData.clientName;
          _this.followups_data = decodedData.followUps;
          _this.data_length = decodedData.counts;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          if (response.status == 403) {
            _this.$router.push({ name: "403" });
          }
          if (response.status == 404) {
            _this.$router.push({ name: "404" });
          }
          if (response.data.type == false) {
            _this.tokenExpired();
          }
        });
    },
  },
};
</script>